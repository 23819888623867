<template>
  <b-container v-touch:swipe.left="swipeLeft" v-touch:swipe.right="swipeRight">
    <b-row>
      <b-col cols="1"/>
        <b-col>
        <h2>Fahrrad wählen</h2>
        </b-col>
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-col cols="10">
        <img src="@/assets/illustrations/womenwithexclamationmark.svg" class="illustration" alt="illustration" style="width: 100%; height: 100%; margin-top: 1.5rem; margin-bottom: 1.25rem;"/>
        </b-col>
      <b-col cols="1"/>
    </b-row>
    <br/>
    <b-row>
      <b-col cols="1"/>
        <b-col cols="10">
          <p style="margin-bottom: 10rem;">Nun kannst du auf den Bike Button des Fahrrads klicken, welches du ausleihen willst. Stehst du direkt neben dem Bike kannst du es direkt entsperren oder es für 30 Minuten blocken, sodass es für den Zeitraum reserviert ist.</p>
        </b-col> 
      <b-col cols="1"/>
    </b-row>
    <b-row class="fixed-bottom pageIndicatorPos">
      <b-col cols="2"/>
      <b-col cols="8">
        <PageIndicator route="appTutorial" :max="6" :pos="3"/>
      </b-col>
      <b-col cols="2"/>
    </b-row>
     <b-row>
      <NextButton text="Weiter" next @click="$router.push('/apptutorial/4')" />
    </b-row>
  </b-container>
</template>

<script>
import PageIndicator from '../components/PageIndicator';
import NextButton from '../components/NextButton';
  export default {
    name: 'AppTutorial3',
    components: {
      PageIndicator,
      NextButton,
    },
    methods: {
      swipeLeft() {
        this.$router.push('/appTutorial/4')
      },
      swipeRight() {
        this.$router.push('/appTutorial/2')
      }
    }
  }
</script>

<style scoped>
.illustration {
  pointer-events: none;
}
</style>